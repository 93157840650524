import React from 'react';
import logo from './logo.svg';
import GuacViewer from './Components/GuacViewer';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <GuacViewer />
      </header>
    </div >
  );
}

export default App;
