import axios from "axios";
import { config } from "process";

export default class GuacApi {
    apiInstance = axios.create({
        baseURL: 'https://stream.dev.avatarmedical.cloud/api/',
        timeout: 30000,
        xsrfCookieName: "csrftoken",
        xsrfHeaderName: "X-CSRFToken",
    });

    token = ''

    constructor() {
        this.apiInstance.interceptors.response.use(response => {
            return response;
        }, (error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("Api error:");
                console.log("Data: ");
                console.log(error.response.data);
                console.log("Status: " + error.response.status);
                console.log("Headers: ");
                console.log(error.response.headers);

            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log("Request: " + error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error: ', error.message);
            }

            return Promise.reject(error);
        }
        );

    }

    getUsers() {
        return this.apiInstance.get("/users/");
    }


    async login(username: string, password: string) {
        let bodyFormData = new FormData();
        bodyFormData.set('username', username);
        bodyFormData.set('password', password);
        let response = await this.apiInstance.post("/tokens", {
            username: username,
            password: password
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })

        this.apiInstance.defaults.headers.common['Guacamole-Token'] = response.data.authToken;
        this.token = response.data.authToken
        console.log(`headers: ${this.token}`);
        return this.token

    }
}