import React, { useCallback, useEffect, useRef, useState } from 'react';
import Guacamole from 'guacamole-common-js';
import { Button, Dimmer, Divider, Loader } from "semantic-ui-react";
import { GUACAMOLE_STATUS, GUACAMOLE_CLIENT_STATES } from "./const";
import GuacApi from '../Api/GuacApi';

interface GuacViewerProps {
    guacServer?: string;
    controlSize?: boolean;
    controlInput?: boolean;
    screenSize?: { width: number, height: number } | null;
}

const GuacViewer: React.FC<GuacViewerProps> = ({
    guacServer = "stream.dev.avatarmedical.cloud",
    controlSize = true,
    controlInput = true,
    screenSize = { width: 1920, height: 1080 },
}) => {
    const displayRef = useRef<HTMLDivElement>(null);
    const guacRef = useRef<Guacamole.Client | null>(null);
    const connectParamsRef = useRef<any>({});
    const scale = useRef<number>(1);
    const demandedScreenSize = useRef<{ width: number, height: number } | null>({ width: 1920, height: 1080 });

    const updateDisplaySizeTimerRef = useRef<number | null>(null);

    const [clientState, setClientState] = useState<number>(0);
    const [guacStatus, setguacStatus] = useState<string>('');
    const [guacToken, setguacToken] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<React.ReactNode | null>(null);

    const guacApi = new GuacApi()

    const getConnectionString = (): string => {
        const params = connectParamsRef.current;
        return Object.keys(params).map((key) => {
            if (Array.isArray(params[key])) {
                return params[key].map((item: string) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&');
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
        }).join('&');
    };

    useEffect(() => {
        if (guacToken) {
            console.log("token not empty")
            const protocolPrefix = window.location.protocol === 'https:' ? 'wss:' : 'wss:';
            const webSocketFullUrl = `${protocolPrefix}//${guacServer}/websocket-tunnel?token=${encodeURIComponent(guacToken)}&GUAC_DATA_SOURCE=postgresql&GUAC_ID=1&GUAC_TYPE=c&GUAC_TIMEZONE=Europe%2FParis&GUAC_AUDIO=audio%2FL8&GUAC_AUDIO=audio%2FL16&GUAC_IMAGE=image%2Fjpeg&GUAC_IMAGE=image%2Fpng&GUAC_IMAGE=image%2Fwebp`;

            guacRef.current = new Guacamole.Client(new Guacamole.WebSocketTunnel(webSocketFullUrl));

            displayRef.current?.appendChild(guacRef.current.getDisplay().getElement());

            guacRef.current.onerror = (error) => {
                let msg = error.message;

                if (GUACAMOLE_STATUS[error.code]) {
                    msg = (
                        <p>
                            {error.message}<br />
                            {GUACAMOLE_STATUS[error.code].name}<br />
                            {GUACAMOLE_STATUS[error.code].text}
                        </p>
                    );
                }

                setErrorMessage(msg);
            };

            guacRef.current.onstatechange = (newState) => {
                setClientState(newState);
            };

            const connectionParams: any = {
                audio: []
            };

            if (controlSize) {
                connectionParams.width = displayRef.current?.clientWidth;
                connectionParams.height = displayRef.current?.clientHeight;
            }

            const supportedAudioTypes = Guacamole.AudioPlayer.getSupportedTypes();
            if (supportedAudioTypes.length > 0) {
                connectionParams.audio = supportedAudioTypes.map(item => `${item};rate=44100,channels=2`);
            }

            connectParamsRef.current = connectionParams;

            console.log("connecting attempt")
            guacRef.current.connect(getConnectionString());
        }

        return () => {
            guacRef.current?.disconnect();
        };
    }, [controlSize, guacToken]);


    useEffect(() => {
        if (!controlInput || !guacRef.current || !displayRef.current) return;
        console.log('input configuring')

        const keyboard = new Guacamole.Keyboard(displayRef.current);

        const fixKeys = (keysym: number) => {
            if (keysym === 65508) return 65507;
            return keysym;
        };

        keyboard.onkeydown = (keysym) => {
            guacRef.current?.sendKeyEvent(1, fixKeys(keysym));
        };

        keyboard.onkeyup = (keysym) => {
            guacRef.current?.sendKeyEvent(0, fixKeys(keysym));
        };

        const mouse = new Guacamole.Mouse(displayRef.current);

        mouse.onmousemove = (mouseState) => {
            mouseState.x /= scale.current;
            mouseState.y /= scale.current;
            guacRef.current?.sendMouseState(mouseState);
        };

        mouse.onmousedown = mouse.onmouseup = (mouseState) => {
            guacRef.current?.sendMouseState(mouseState);
        };
    }, [controlInput, guacStatus, guacRef.current, displayRef.current]);

    useEffect(() => {
        console.log(guacStatus)
        if (guacStatus === '') {
            setguacStatus('Authenticated')
            console.log('authenticating')
            guacApi.login('guacadmin', 'guacadmin').then((token) => { setguacToken(token) })
        }
    }, [guacStatus]);

    const rescaleDisplay = useCallback(() => {
        if (!guacRef.current || !displayRef.current) return;

        const remoteDisplayWidth = guacRef.current.getDisplay().getWidth();
        const remoteDisplayHeight = guacRef.current.getDisplay().getHeight();

        const newWidth = displayRef.current.clientWidth;
        const newHeight = displayRef.current.clientHeight;

        const newScale = Math.min(newWidth / remoteDisplayWidth, newHeight / remoteDisplayHeight, 1);

        guacRef.current.getDisplay().scale(newScale);
        scale.current = newScale;
    }, []);


    return (
        //<div ref={displayRef} style={{ display: "flex", width: "100%", height: "100%", outline: "none" }} />
        <Dimmer.Dimmable as="div" dimmed={clientState > 2} style={{ flex: "1", display: "flex", width: "100%", height: "100%" }}>
            <Dimmer active={clientState > 2} inverted>
                {clientState === 3 ? <Loader inverted /> : null}
                {clientState > 3 ?
                    <div style={{ textAlign: "center", maxWidth: "50%" }}>
                        <p>Client State: {GUACAMOLE_CLIENT_STATES[clientState]}</p>
                        <Divider />
                        {errorMessage}
                        <Divider />
                        <Button size="large" >Reconnect</Button>
                    </div> : null
                }
            </Dimmer>
            <div ref={displayRef} style={{ display: "flex", width: "100%", height: "100%", outline: "none" }} />
        </Dimmer.Dimmable>
    );
};

export default GuacViewer;
